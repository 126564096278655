<template>
    <div class="content__list">
        <div class="category-display">  
            <div class="product-category analysis__card" @click="selectCategory('transactions')">
                
                <div class="analysis__image" :style="`background-image:url(${require('@/assets/images/transactions.png')})`"></div>
                <h1 class="product-category__heading">Transactions And Records</h1>
               
            </div>
            <div class="product-category analysis__card">
                
                <div class="analysis__image" :style="`background-image:url(${require('@/assets/images/kpi.png')})`"></div>
                <h1 class="product-category__heading">KPI's<br><span style="opacity:0;">KPI's</span></h1>
            </div>

            <div class="product-category analysis__card" @click="selectCategory('product-summary') ">
                
                <div class="analysis__image" :style="`background-image:url(${require('@/assets/images/product_summary.png')})`"></div>
                <h1 class="product-category__heading">Product Summary</h1>
               
            </div>
        </div>
    </div>
</template>


<script>

export default{
    methods:{
        selectCategory(category){
            
            this.$router.push({name:category,params:{id:this.id}})
        },
        setBranchId(){
			this.$store.commit({type:'setBranchId',branchId:this.id})
		},
    },
    computed:{
        getBranchId(){
			return this.$store.state.branchId
		},
        id(){
            return this.$route.params.id
        }
    },

    mounted(){
        this.$store.commit({type:'stopAuthenticationLoader'})
        if(!this.getBranchId && this.id) this.setBranchId()
        console.log(this.$route)
    }
}
</script>

<style>
    .product-category{
        display:flex;
        flex-direction:column;
        align-items:center;
        justify-content:center;
    }
    .analysis__image{
        height:84px;
        width: 84px;
        margin:1em auto;
        background-size: cover;
        opacity: 0.5;
    }
</style>